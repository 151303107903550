<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Nombre
    </template>
    <template v-slot:subtitle>
      Añadir banca
    </template>
    <template #content>
      <div class="filters">

        <!--          Name-->
        <div class="field">
          <span class="p-float-label">
            <InputText id="name" type="text" v-model="bankingName" class="form-input"/>
            <label for="name">Nombre de banca</label>
          </span>
        </div>


      </div>

    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-end">
        <!--          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />-->
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="this.bankingName ? disabled : ''"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>



export default {
  name: "Step1",
  created() {
    this.bankingName = this.$store.getters.getBanking.bankingName;
  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/bancas/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/bancas/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/bancas/agregar-3'
        },
      ],
      bankingName: null,
      pageIndex: 0,
      roleId: null,
    }
  },

  methods: {
    nextPage() {
      this.$store.commit('setBanking', {
        id_comercializador: this.$store.getters.getBanking.id_comercializador,
        id_banca: this.$store.getters.getBanking.id_banca,
        bankingName: this.bankingName,
        id_estado: this.$store.getters.getBanking.id_estado,
        id_municipio: this.$store.getters.getBanking.id_municipio,
      });
      this.$router.push({name: 'bancas/agregar-2',})
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

</style>